.root {
    border: 1px solid var(--ion-color-primary);
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    overflow: hidden;
}

.root:focus-within {
    box-shadow: 0 0 3px var(--ion-color-primary);
}

.root ion-item {
    --padding-start: 5px;
    --inner-padding-end: 5px;
    --inner-padding-top: 5px;
    --inner-padding-bottom: 5px;
    --inner-padding-end: 5px;

    --min-height: 5px;
}


.second {
    margin-right: 5px;
}
 .cha-label {
    font-weight: 500;
}

 .cha-sub-label {
    font-size: 0.825rem;
    color: var(--ion-color-medium)
}


.root .input {
    text-align: right;
}

.input.ts1 {
    font-size: 1rem;
}

.input.ts2 {
    font-size: 1.25rem;
}

.input.ts3 {
    font-size: 1.5rem;
}

.input.ts4 {
    font-size: 2rem;
}

.input.ts5 {
    font-size: 2.5rem;
}

.input.ts6 {
    font-size: 3rem;
}

.short {
    background-color: var(--ion-color-primary);
    padding: 10px;
    border-radius: 5px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
    margin-right: 10px;
    font-weight: 600;
}

.short.l1 {
    font-size: 1.5rem;
}

.short.l2 {
    font-size: 1.25rem;
}

.short.l3 {
    font-size: 1rem;
}

.short.l4 {
    font-size: 0.875rem;
}